import React, { useCallback, useEffect, useState } from "react";
import TypeformEmbed from "../../components/TypeformEmbed";
import { useParams } from "react-router-dom";
import { DEFAULT_MATCHING_SESSION_ID } from "../../contstants/questionnaire";
import { getHiddenFields, setParams } from "../../helpers/params";
import Loader from "../../components/Loader";

const MatchingPage = () => {
  const { id } = useParams();
  const formId = id || DEFAULT_MATCHING_SESSION_ID;
  const [hiddenFields, setHiddenFields] = useState<{ [key: string]: any }>();

  useEffect(() => {
    setParams();
  }, []);

  const setAidHiddenField = useCallback(async () => {
    const hiddenFields = await getHiddenFields(formId);
    setHiddenFields(hiddenFields);
  }, [formId]);

  useEffect(() => {
    setAidHiddenField();
  }, [setAidHiddenField]);

  return hiddenFields ? (
    <TypeformEmbed formId={formId} hiddenFields={hiddenFields} />
  ) : (
    <Loader />
  );
};

export default MatchingPage;
