import { Widget } from "@typeform/embed-react";

const TypeformEmbed = ({
  formId,
  hiddenFields,
}: {
  formId: string;
  hiddenFields?: { [key: string]: any };
}) => {
  return (
    <Widget
      id={formId}
      style={{ width: "100%", height: "100vh" }}
      hidden={hiddenFields}
      className="my-form"
    />
  );
};

export default TypeformEmbed;
